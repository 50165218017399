import React from "react";

const BlogEnglish = ({ location }) => {
	return (
		<>
			<h2>blog</h2>
		</>
	);
};
export default BlogEnglish;
